/*****************  Font Sizes  **********************/
.font-size-8{     font-size:8px; }
.font-size-9{     font-size:9px; }
.font-size-10{    font-size:10px;}
.font-size-11{    font-size:11px;}
.font-size-12{    font-size:12px;}
.font-size-13{    font-size:13px;}
.font-size-14{    font-size:14px;}
.font-size-15{    font-size:15px;}
.font-size-16{    font-size:16px;}
.font-size-17{    font-size:17px;}
.font-size-18{    font-size:18px;}
.font-size-19{    font-size:19px;}
.font-size-20{    font-size:20px;}
.font-size-21{    font-size:21px;}
.font-size-22{    font-size:22px;}
.font-size-23{    font-size:23px;}
.font-size-24{    font-size:24px;}
.font-size-25{    font-size:25px;}
.font-size-26{    font-size:26px;}
.font-size-32{    font-size:32px;}
.font-size-36{    font-size:36px;}
.font-size-48{    font-size:48px;}
.font-size-72{    font-size:72px;}

/*****************  Spacers   **********************/
.space-5{    height:5px;}
.space-10{    height:10px;}
.space-15{    height:15px;}
.space-20{    height:20px;}
.space-25{    height:25px;}
.space-30{    height:30px;}
.space-35{    height:35px;}
.space-40{    height:40px;}
.space-45{    height:45px;}
.space-50{    height:50px;}
.space-75{    height:75px;}
.space-100{    height:100px;}
.space-150{    height:150px;}
.space-175{    height:175px;}
.space-200{    height:200px;}




/******* Margin Helpers *******/
/*		Margin-All					*/
.m-0 { margin: 0px; } .m-5 { margin: 5px; } .m-10 { margin: 10px; } .m-15 { margin: 15px; } .m-20 { margin: 20px; } .m-25 { margin: 25px; } .m-30 { margin: 30px; } .m-35 { margin: 35px; }
/*		Margin-left					*/
.ml-3  { margin-left: 3px; } .ml-5  { margin-left: 5px; } .ml-10 { margin-left: 10px; } .ml-15 { margin-left: 15px; } .ml-20 { margin-left: 20px; } .ml-25 { margin-left: 25px; } .ml-30 { margin-left: 30px; }
/*		Margin-right				*/
.mr-3  { margin-right: 3px; } .mr-5  { margin-right: 5px; } .mr-10 { margin-right: 10px; } .mr-15 { margin-right: 15px; } .mr-20 { margin-right: 20px; } .mr-25 { margin-right: 25px; } .mr-30 { margin-right: 30px; }
/*		Margin-Horizontal			*/
.mh-5 	{ margin-left: 5px; margin-right: 5px; } .mh-10 { margin-left: 10px; margin-right: 10px; } .mh-15 { margin-left: 15px; margin-right: 15px; } .mh-20 { margin-left: 20px; margin-right: 20px; } .mh-25 { margin-left: 25px; margin-right: 25px; } .mh-30 { margin-left: 30px; margin-right: 30px }
/*		Margin Vertical				*/
.mv-5 	{ margin: 5px 0; } .mv-10 { margin: 10px 0; } .mv-15 { margin: 15px 0; } .mv-20 { margin: 20px 0; } .mv-25 { margin: 25px 0px; } .mv-25 { margin:30px 0; }
/*		Margin-bottom			*/
.mt-3  { margin-bottom: 3px; } .mt-5  { margin-bottom: 5px; } .mt-10 { margin-bottom: 10px; } .mt-15 { margin-bottom: 15px; } .mt-20 { margin-bottom: 20px; } .mt-25 { margin-bottom: 25px; } .mt-30 { margin-bottom: 30px; }
/*		Margin-bottom		*/
.mb-3  { margin-bottom: 3px; } .mb-5  { margin-bottom: 5px; } .mb-10 { margin-bottom: 10px; } .mb-15 { margin-bottom: 15px; } .mb-20 { margin-bottom: 20px; } .mb-25 { margin-bottom: 25px; } .mb-30 { margin-bottom: 30px; }


/******* Padding Helpers *******/
/*		Padding-All					*/
.p-0 { padding: 0px; } .p-5 { padding: 5px; } .p-10 { padding: 10px; } .p-15 { padding: 15px; } .p-20 { padding: 20px; } .p-25 { padding: 25px; } .p-30 { padding: 30px; } .p-35 { padding: 35px; }
/*		Padding-left					*/
.pl-3  { padding-left: 3px; } .pl-5  { padding-left: 5px; } .pl-10 { padding-left: 10px; } .pl-15 { padding-left: 15px; } .pl-20 { padding-left: 20px; } .pl-25 { padding-left: 25px; } .pl-30 { padding-left: 30px; }
/*		Madding-right				*/
.pr-3  { padding-right: 3px; } .pr-5  { padding-right: 5px; } .pr-10 { padding-right: 10px; } .pr-15 { padding-right: 15px; } .pr-20 { padding-right: 20px; } .pr-25 { padding-right: 25px; } .pr-30 { padding-right: 30px; }
/*		Padding-Horizontal			*/
.ph-5 	{ padding-left: 5px; padding-right: 5px; } .ph-10 { padding-left: 10px; padding-right: 10px; } .ph-15 { padding-left: 15px; padding-right: 15px; } .ph-20 { padding-left: 20px; padding-right: 20px; } .ph-25 { padding-left: 25px; padding-right: 25px; } .ph-30 { padding-left: 30px; padding-right: 30px }
/*		Padding Vertical				*/
.pv-5 	{ padding: 5px 0; } .pv-10 { padding: 10px 0; } .pv-15 { padding: 15px 0; } .pv-20 { padding: 20px 0; } .pv-25 { padding: 25px 0px; } .pv-25 { padding:30px 0; }
/*		Padding-bottom			*/
.pt-3  { padding-bottop: 3px; } .pt-5  { padding-bottop: 5px; } .pt-10 { padding-bottop: 10px; } .pt-15 { padding-bottop: 15px; } .pt-20 { padding-bottop: 20px; } .pt-25 { padding-bottop: 25px; } .pt-30 { padding-bottop: 30px; }
/*		Padding-bottom		*/
.pb-3  { padding-bottop: 3px; } .pb-5  { padding-bottop: 5px; } .pb-10 { padding-bottop: 10px; } .pb-15 { padding-bottop: 15px; } .pb-20 { padding-bottop: 20px; } .pb-25 { padding-bottop: 25px; } .pb-30 { padding-bottop: 30px; }    


/*****************  custom helpers  **********************/

.no-margin{   margin: 0;}
.no-padding{    padding: 0;}
.no-round{    border-radius: 0; }
.no-border{
  border: none !important
}

.required-marker {
  float: right;
  margin-right: 0;
  margin-top: -30px;
  position: relative;
  z-index: 2;
  color: red;
}

label.error{
    color: red;
}


.embed-container { position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; } .embed-container iframe, .embed-container object, .embed-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }


@media (max-width: 767px) {

  .text-center-xs{ text-align: center;}

}